import React, {useState, useEffect} from 'react';
import {List, Button, Typography, Layout, message} from 'antd';
import AlquilerService from '../services/AlquilerService';
import {Link} from 'react-router-dom';

const {Title} = Typography;
const {Content} = Layout;

const AlquilerList = () => {
    const [alquileres, setAlquileres] = useState([]);

    useEffect(() => {
        AlquilerService.obtenerTodos().then(response => {
            setAlquileres(response.data);
        }).catch(error => {
            message.error('Error fetching alquileres:', error);
        });
    }, []);

    return (
        <Layout style={{padding: '24px', minHeight: '100vh'}}>
            <Content style={{maxWidth: '1200px', margin: '0 auto'}}>
                <Title level={2} style={{textAlign: 'center'}}>Lista de Alquileres</Title>
                <List
                    itemLayout="horizontal"
                    dataSource={alquileres}
                    renderItem={alquiler => (
                        <List.Item
                            style={{
                                marginBottom: '16px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                                minWidth: '350px',  // Tamaño mínimo ajustado
                                padding: '16px',     // Espaciado interno
                            }}
                            actions={[
                                <Link to={`/alquileres/${alquiler.id}`}>
                                    <Button type="link" style={{fontWeight: 'bold'}}>Ver</Button>
                                </Link>
                            ]}
                        >
                            <List.Item.Meta
                                title={
                                    <div style={{fontSize: '16px'}}>
                                        {alquiler.id && (
                                            <>
                                                <span style={{display: 'block', color: alquiler.cliente ? 'inherit' : 'red'}}>
                                                    {alquiler.cliente ? alquiler.cliente.nombre : "SIN CLIENTE"}
                                                </span>
                                                <span style={{ display: 'block', color: alquiler.contenedor ? 'inherit' : 'red'}}>
                                                    {alquiler.contenedor ? alquiler.contenedor.codigo : "SIN CONTENEDOR"}
                                                </span>
                                            </>
                                        )}


                                    </div>

                                }
                                description={`Fecha de alquiler: ${alquiler.fechaInicio}`}
                            />

                        </List.Item>
                    )}
                />
                <div style={{textAlign: 'center'}}>
                    <Link to="/alquileres/nuevo">
                        <Button type="primary" size="large" style={{borderRadius: '8px'}}>Crear nuevo alquiler</Button>
                    </Link>
                </div>
            </Content>
        </Layout>
    );
}

export default AlquilerList;
