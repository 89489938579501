// src/components/ContenedorList.js
import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Button, Typography, message, List, Row, Col, Badge, Popover, Tag} from 'antd';
import {WarningOutlined} from '@ant-design/icons';
import ContenedorService from '../services/ContenedorService';

const {Title} = Typography;

const ReportesList = ({reportes}) => (
    <List
        size="small"
        dataSource={reportes.filter(r => !r.resuelto)}
        renderItem={(reporte) => (
            <List.Item>
                <Typography.Text ellipsis style={{maxWidth: 200}}>
                    {reporte.descripcion}
                </Typography.Text>
                <Tag color="orange" style={{marginLeft: 8}}>
                    {reporte.fechaReporte}
                </Tag>
            </List.Item>
        )}
    />
);

const ContenedorList = () => {
    const [contenedores, setContenedores] = useState([]);

    useEffect(() => {
        ContenedorService.obtenerTodos()
            .then((response) => setContenedores(response.data))
            .catch((error) => message.error('Error al obtener contenedores:', error));
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Contenedores</Title>
            <List
                bordered
                dataSource={contenedores}
                renderItem={(contenedor, index) => (
                    <List.Item>
                        <Row style={{width: '100%'}} align="middle" gutter={[16, 0]}>
                            {/* Columna de numeración */}
                            <Col style={{fontWeight: 'bold', fontSize: '1rem', color: 'gray'}}>
                                {index + 1}.
                            </Col>

                            {/* Columna de enlace del contenedor */}
                            <Col flex="auto">
                                <Link to={`/contenedores/${contenedor.id}`}
                                      style={{fontWeight: 'bold', fontSize: '1rem'}}>
                                    {contenedor.codigo}
                                </Link>
                            </Col>

                            {/* Columna del ícono de estado */}
                            <Col>
                                {contenedor.disponible ? (
                                    <i className="bi bi-check-circle-fill"
                                       style={{fontSize: '1.2rem', color: 'lightgreen'}}/>
                                ) : (
                                    <i className="bi bi-exclamation-circle-fill"
                                       style={{fontSize: '1.2rem', color: 'orange'}}/>
                                )}
                            </Col>
                            {/* Columna de cantidad de reportes activos */}
                            {contenedor.cantidadReportesActivos > 0 && (
                                <Col>
                                    <Popover 
                                        content={<ReportesList reportes={contenedor.reportes || []} />}
                                        title="Reportes Pendientes"
                                        trigger="click"
                                        placement="right"
                                    >
                                        <Badge 
                                            count={contenedor.cantidadReportesActivos}
                                            style={{ backgroundColor: '#faad14' }}
                                        >
                                            <WarningOutlined style={{ fontSize: '16px', color: '#faad14' }} />
                                        </Badge>
                                    </Popover>
                                </Col>
                            )}
                        </Row>
                    </List.Item>
                )}
            />
            <div style={{marginTop: '1rem'}}>
                <Link to="/contenedores/nuevo">
                    <Button type="primary">Nuevo contenedor</Button>
                </Link>
            </div>
        </div>
    );
};

export default ContenedorList;
