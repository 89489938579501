import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import ClienteService from '../services/ClienteService';
import {List, Typography, Button, message} from 'antd';

const {Title} = Typography;

const ClienteList = () => {
    const [clientes, setClientes] = useState([]);

    useEffect(() => {
        ClienteService.obtenerTodos().then(response => {
            setClientes(response.data);
        }).catch(error => {
            message.error('Error fetching clientes:', error);
            message.error('Error al obtener la lista de clientes');
        });
    }, []);

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>Lista de Clientes</Title>
            <List
                bordered
                dataSource={clientes}
                renderItem={(cliente, index) => (
                    <List.Item>
                        <b>{index + 1}.</b> <Link to={`/clientes/${cliente.id}`}>{cliente.nombre}</Link>
                    </List.Item>
                )}
            />

            <div style={{marginTop: '1rem'}}>
                <Link to="/clientes/nuevo">
                    <Button type="primary">Crear nuevo cliente</Button>
                </Link>
            </div>
        </div>
    );
}

export default ClienteList;
