import React, {useState, useEffect} from 'react';
import ModeloContratoService from '../services/ModeloContratoService';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Button, Typography, message, Modal} from 'antd';
import FacturaService from "../services/FacturaService";

const {Title} = Typography;
const {TextArea} = Input;

const ModeloContratoForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [modeloContrato, setModeloContrato] = useState({titulo: '', contenido: ''});
    const [isModalVisible, setIsModalVisible] = useState(false);


    useEffect(() => {
        if (id) {
            ModeloContratoService.obtenerPorId(id).then(response => {
                setModeloContrato(response.data);
            }).catch(error => {
                message.error('Error al obtener el modelo de contrato');
            });
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            if (id) {
                await ModeloContratoService.actualizar(id, modeloContrato);
            } else {
                await ModeloContratoService.guardar(modeloContrato);
            }
            message.success('Modelo guardado exitosamente');
            navigate('/modelosContrato');
        } catch (error) {
            message.error('Error al guardar el modelo');
        }
    };

    const handleContentChange = (e) => {
        setModeloContrato({...modeloContrato, contenido: e.target.value});
    };

    const handlePlaceholderInsert = (placeholder) => {
        const textArea = document.getElementById("contenido-textarea");
        const startPos = textArea.selectionStart;
        const endPos = textArea.selectionEnd;
        const currentContent = modeloContrato.contenido;

        // Insertar el placeholder en la posición actual del cursor
        const newContent = currentContent.slice(0, startPos) + placeholder + currentContent.slice(endPos);

        setModeloContrato({...modeloContrato, contenido: newContent});

        // Mover el cursor a la posición después del placeholder insertado
        setTimeout(() => {
            textArea.selectionStart = textArea.selectionEnd = startPos + placeholder.length;
            textArea.focus();
        }, 0);

        setIsModalVisible(false); // Cerrar el modal al seleccionar un placeholder
    };

    const handleDelete = async () => {
        Modal.confirm({
            title: 'Eliminar modelo de contrato',
            content: '¿Estás seguro de que deseas eliminar este modelo?',
            okText: 'Eliminar',
            cancelText: 'Cancelar',
            onOk: async () => {
                try {
                    await ModeloContratoService.eliminar(id);
                    message.warning('Modelo eliminado.');
                    navigate('/modelosContrato');
                } catch (error) {
                    message.error(`Error al eliminar el modelo: ${error.message}`);
                }
            },
        });
    };
    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? modeloContrato.titulo : 'Crear modelo de contrato'}</Title>
            <Form layout="vertical" onFinish={handleSubmit}>
                <Form.Item label="Título" required>
                    <Input
                        value={modeloContrato.titulo}
                        onChange={e => setModeloContrato({...modeloContrato, titulo: e.target.value})}
                        placeholder="Ingrese el título del contrato"
                    />
                </Form.Item>
                <Form.Item label="Contenido del contrato" required>
                    <TextArea
                        id="contenido-textarea"
                        value={modeloContrato.contenido}
                        onChange={handleContentChange}
                        rows={10}
                        placeholder="Ingrese el contenido del contrato"
                        style={{resize: 'vertical', padding: '10px', fontSize: '16px'}}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Guardar</Button>
                    <Button
                        style={{marginLeft: 10}}
                        onClick={() => setIsModalVisible(true)}
                    >
                        Insertar campo
                    </Button>
                    {id && (
                        <Button danger onClick={handleDelete} style={{marginLeft: 10}}>
                            Eliminar
                        </Button>
                    )}
                </Form.Item>
            </Form>

            {/* Modal para mostrar los placeholders */}
            <Modal
                title="Seleccionar un campo"
                visible={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                footer={null}
            >
                <p>Al tocar un botón, se va a escribir un código en el modelo que representa lo que va a ir cuando se genere el contrato real.</p>
                <h4>Empresa</h4>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{NOMBRE_EMPRESA}")}>
                    Nombre
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{DIRECCION_EMPRESA}")}>
                    Dirección
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{CUIT_EMPRESA}")}>
                    CUIT
                </Button>

                <h4>Cliente</h4>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{NOMBRE_CLIENTE}")}>
                    Nombre
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{DIRECCION_CLIENTE}")}>
                    Dirección
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{CUIT_CLIENTE}")}>
                    CUIT
                </Button>

                <h4>Garante</h4>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{NOMBRE_GARANTE}")}>
                    Nombre
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{DIRECCION_GARANTE}")}>
                    Dirección
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{CUIT_GARANTE}")}>
                    CUIT
                </Button>

                <h4>El que factura</h4>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{NOMBRE_EMISOR}")}>
                    Nombre
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{DIRECCION_EMISOR}")}>
                    Dirección
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{CUIT_EMISOR}")}>
                    CUIT
                </Button>

                <h4>Contenedor</h4>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{CODIGO_CONTENEDOR}")}>
                    Código
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{LONGITUD_CONTENEDOR}")}>
                    Longitud
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{TIPO_CONTENEDOR}")}>
                    Tipo
                </Button>

                <h4>Otros datos</h4>

                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{FECHA_INICIO}")}>
                    Inicio del contrato
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{UBICACION}")}>
                    Ubicación
                </Button>
                <Button style={{cursor: 'pointer'}} onClick={() => handlePlaceholderInsert("{MONTO}")}>
                    Monto
                </Button>
            </Modal>
        </div>
    );
};

export default ModeloContratoForm;
