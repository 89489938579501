import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Button, message, Typography} from 'antd';
import ClienteService from '../services/ClienteService';
import EmisorService from '../services/EmisorService';

const {Title} = Typography;

const ClienteForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [cliente, setCliente] = useState({id: '', nombre: '', cuit: '', direccion: '', telefono: '', email: ''});

    useEffect(() => {
        if (id) {
            ClienteService.obtenerPorId(id).then(response => {
                setCliente(response.data);
                form.setFieldsValue(response.data);
            }).catch(error => {
                message.error('Error al obtener cliente:', error);
            });
        }
    }, [id, form]);

    const handleSubmit = (event) => {
        const clienteData = {
            nombre: cliente.nombre,
            cuit: cliente.cuit,
            direccion: cliente.direccion,
            telefono: cliente.telefono,
            email: cliente.email,
        }
        if (id) {
            ClienteService.actualizar(id, clienteData).then(() => {
                navigate('/clientes');
            }).catch(error => {
                message.error('Error al guardar cliente:', error);
            });
        } else {
            ClienteService.guardar(clienteData).then(() => {
                navigate('/clientes');
            }).catch(error => {
                message.error('Error al crear cliente:', error);
            });
        }
    }

    const handleDelete = () => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este cliente?')) {
            ClienteService.eliminar(id).then(() => { // Cambiar EmisorService por ClienteService
                navigate('/clientes');
            }).catch(error => {
                message.error('Error al eliminar el cliente:', error);
            });
        }
    }

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '6rem 2rem 3rem 4rem'}}>
            <Title level={2}>{id ? 'Cliente - ' : 'Crear cliente'} {cliente.nombre ? cliente.nombre : ""}</Title>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={cliente}
                style={{maxWidth: '30em'}}
            >
                <Form.Item
                    label="Nombre"
                    name="nombre"
                    rules={[{required: true, message: 'Ingrese el nombre'}]}
                >
                    <Input onChange={e => setCliente({...cliente, nombre: e.target.value})}/>
                </Form.Item>

                <Form.Item
                    label="CUIT"
                    name="cuit"
                    rules={[
                        {required: true, message: 'Ingrese el CUIT'},
                        {
                            pattern: /^\d{2}-\d{8}-\d{1}$/,
                            message: 'El CUIT debe tener el formato XX-XXXXXXXX-X',
                        },
                    ]}
                >
                    <Input onChange={e => setCliente({...cliente, cuit: e.target.value})}/>
                </Form.Item>

                <Form.Item
                    label="Dirección"
                    name="direccion"
                >
                    <Input onChange={e => setCliente({...cliente, direccion: e.target.value})}/>
                </Form.Item>

                <Form.Item
                    label="Teléfono"
                    name="telefono"
                >
                    <Input onChange={e => setCliente({...cliente, telefono: e.target.value})}/>
                </Form.Item>

                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {type: 'email', message: 'Ingrese un email válido'},
                    ]}
                >
                    <Input onChange={e => setCliente({...cliente, email: e.target.value})}/>
                </Form.Item>
                <hr/>
                <Button type="primary" htmlType="submit">Guardar</Button>
                {id && (
                    <Button
                        type="danger"
                        onClick={handleDelete}
                        style={{marginLeft: '10px'}}
                    >
                        Eliminar
                    </Button>
                )}
            </Form>
        </div>
    );
};

export default ClienteForm;
