import React, {useState, useEffect} from 'react';
import EmisorService from '../services/EmisorService';
import {useParams, useNavigate} from 'react-router-dom';
import {Form, Input, Button, Typography, message, Modal} from 'antd';

const {Title} = Typography;

const EmisorForm = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();

    useEffect(() => {
        if (id) {
            EmisorService.obtenerPorId(id)
                .then(response => form.setFieldsValue(response.data))
                .catch(error => {
                    message.error('Error al cargar el emisor.');
                    console.error(error);
                });
        }
    }, [id, form]);

    const handleSubmit = async (values) => {
        try {
            if (id) {
                await EmisorService.actualizar(id, values);
                message.success('Emisor actualizado exitosamente');
            } else {
                await EmisorService.guardar(values);
                message.success('Emisor creado exitosamente');
            }
            navigate('/emisores');
        } catch (error) {
            message.error('Error al guardar el emisor.');
            console.error(error);
        }
    };

    const handleDelete = () => {
        Modal.confirm({
            title: '¿Estás seguro de que deseas eliminar este emisor?',
            content: 'Esta acción no se puede deshacer.',
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk: async () => {
                try {
                    await EmisorService.eliminar(id);
                    message.success('Emisor eliminado exitosamente');
                    navigate('/emisores');
                } catch (error) {
                    message.error('Error al eliminar el emisor.');
                    console.error(error);
                }
            },
        });
    };

    return (
        <div style={{maxWidth: 500, margin: 'auto', padding: '2rem'}}>
            <Title level={2}>{id ? 'Editar Emisor' : 'Crear Emisor'}</Title>
            <Form
                form={form}
                layout="vertical"
                onFinish={handleSubmit}
                initialValues={{
                    razonSocial: '',
                    cuit: '',
                    direccion: '',
                    puntoVenta: '',
                    condicion: '',
                }}
            >
                <Title level={4}>Datos para facturar</Title>
                <Form.Item
                    label="Razón Social"
                    name="razonSocial"
                    rules={[{required: true, message: 'Ingrese la razón social'}]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="CUIT"
                    name="cuit"
                    rules={[
                        {required: true, message: 'Ingrese el CUIT'},
                        {
                            pattern: /^\d{2}-\d{8}-\d{1}$/,
                            message: 'El CUIT debe tener el formato XX-XXXXXXXX-X',
                        },
                    ]}
                >
                    <Input placeholder="Ejemplo: 20-12345678-9"/>
                </Form.Item>
                <Form.Item label="Dirección" name="direccion">
                    <Input/>
                </Form.Item>
                <Form.Item label="Punto de Venta" name="puntoVenta">
                    <Input placeholder="Ejemplo: 0001"/>
                </Form.Item>
                <Form.Item label="Condición" name="condicion">
                    <Input/>
                </Form.Item>
                <Title level={4}>Datos bancarios</Title>
                <Form.Item label="Banco" name="banco">
                    <Input/>
                </Form.Item>
                <Form.Item label="CBU" name="cbu">
                    <Input/>
                </Form.Item>
                <Form.Item label="Alias" name="alias">
                    <Input/>
                </Form.Item>
                <Form.Item label="Número de Cuenta" name="nroCuenta">
                    <Input/>
                </Form.Item>
                <Title level={4}>Datos AFIP</Title>
                <Form.Item label="Certificado" name="certificado">
                    <Input/>
                </Form.Item>
                <Form.Item label="Clave privada" name="clavePrivada">
                    <Input/>
                </Form.Item>
                <div style={{display: 'flex', gap: '10px'}}>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                    {id && (
                        <Button danger onClick={handleDelete}>
                            Eliminar
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default EmisorForm;
